import React, { useState } from "react";
import { graphql, withPrefix } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container, Row, Col } from "reactstrap";
import LightboxController from "../components/lightbox/controller";
import Img from "gatsby-image";
import SideArrow from "../components/icons/arrows/SideArrow";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";
import { Waypoint } from "react-waypoint";
import { animated, useSpring } from "react-spring";
import ERotate from "../components/icons/animation/eRotation-orig.js";
//import AnimatePhotos from '../components/home/twinphotos';

const About = ({ data }) => {
  const [on, toggle] = useState(false);

  const fadein = useSpring({
    opacity: on ? 1 : 0,
    from: { opacity: 0, transform: "translate3d(0,50px,0)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate3d(0,0,0px)" : "translate3d(0,50px,0)"
    },
    config: { tension: 100 }
  });

// eslint-disable-next-line
  let parallax;
  return (
    <Parallax ref={ref => (parallax = ref)}>
      <Layout>
        <SEO
          title={`${data.wpgraphql.page.title}`}
          description={data.wpgraphql.page.acf_page_meta.metadescription}
        />
        <article>
          <header className="header--fullwidth header--lightbg">
            {data.wpgraphql.page.featuredImage != null && (
              <Img
                className="header__image"
                fluid={
                  data.wpgraphql.page.featuredImage.imageFile.childImageSharp
                    .fluid
                }
              />
            )}
            <Container>
              <Row>
                <Col xs={{ offset: 1 }}>
                  <h1 className="heading1">
                    Decades of
                    <span>Multifamily Experience. </span>
                  </h1>
                </Col>
              </Row>
            </Container>
            <ERotate eclass={`logo-e`} />
          </header>

          <section className="section__callout">
            <Container className="container-fluid">
              <Row>
                <Col
                  className="callout__text"
                  sm={{ size: 6, offset: 0 }}
                  md={{ size: 6, offset: 1, order: 2 }}
                  lg={{ size: 5 }}
                >
                  <h3 className="heading2">
                    {data.wpgraphql.page.acf_about.heroSubhead}
                  </h3>
                  <p className="body-copy">
                    {data.wpgraphql.page.acf_about.heroText}
                  </p>
                  <a
                    className="btn btn-primary"
                    rel="noopener noreferrer"
                    href={withPrefix("/pdfs/Ideal_PitchDeck-2020.pdf")}
                    target="_blank"
                  >
                    {" "}
                    Download PDF
                  </a>
                </Col>
                <Col
                  className="callout__images"
                  sm={{ size: 7, offset: 1 }}
                  md={{ size: 6, offset: 0, order: 2 }}
                  lg={{ size: 5 }}
                >
                  {data.wpgraphql.page.acf_about.topImageBack != null && (
                    <ParallaxLayer
                      offset={0.1}
                      speed={0.4}
                      className="d-none d-lg-block"
                    >
                      <Img
                        className="callout__back-image "
                        fixed={
                          data.wpgraphql.page.acf_about.topImageBack.imageFile
                            .childImageSharp.fixed
                        }
                        alt={data.wpgraphql.page.acf_about.topImageBack.altText}
                      />
                    </ParallaxLayer>
                  )}
                  <Waypoint
                    bottomOffset="20%"
                    onEnter={() => {
                      if (!on) toggle(true);
                    }}
                  />
                  <animated.div style={fadein}>
                    {data.wpgraphql.page.acf_about.topImageFront != null && (
                      <Img
                        className="callout__front-image mt-5"
                        fixed={
                          data.wpgraphql.page.acf_about.topImageFront.imageFile
                            .childImageSharp.fixed
                        }
                        alt={data.wpgraphql.page.acf_about.topImageBack.altText}
                      />
                    )}
                  </animated.div>
                  {/* {data.wpgraphql.page.acf_about.topImageFront != null && (
                  <AnimatePhotos
                    frontphoto={
                      data.wpgraphql.page.acf_about.topImageFront.imageFile
                        .childImageSharp.fixed.src
                    }
                    backphoto={
                      data.wpgraphql.page.acf_about.topImageBack.imageFile
                        .childImageSharp.fixed
                    }
                    backphotoalt={
                      data.wpgraphql.page.acf_about.topImageBack.altText
                    }
                    photodirection={`backphotoleft`}
                    extrabackclass={`  w-100 d-xl-block`}
                    extrafrontclass={` mt-5`}
                  />
                )} */}
                </Col>
              </Row>
            </Container>
          </section>
          <ParallaxLayer
            offset={-0.07}
            speed={0.15}
            className="parallax__arrow-container  d-none d-md-block"
          >
            <SideArrow className="parallax__arrow-right" />
          </ParallaxLayer>
          <Container>
            <Row>
              <Col>
                <h2 className="heading2">Our Team</h2>
              </Col>
            </Row>

            <LightboxController />
          </Container>

          <section className="section__giving-back section__featured-properties">
            <Container>
              <Row className="mb-5">
                <Col
                  className="callout__text"
                  sm={{ size: 7, offset: 1 }}
                  md={{ size: 6 }}
                  lg={{ size: 5 }}
                >
                  <h2 className="heading2">
                    {data.wpgraphql.page.acf_about.section1.head}
                  </h2>
                  <p className="body-copy">
                    {data.wpgraphql.page.acf_about.section1.text}
                  </p>
                  <h4 className="blockquote">
                    {data.wpgraphql.page.acf_about.section1.subhead}
                  </h4>
                </Col>
                <Col
                  className="callout__text"
                  sm={{ size: 6, offset: 0 }}
                  md={{ size: 6, offset: 1 }}
                  lg={{ size: 5, offset: 0 }}
                >
                  {data.wpgraphql.page.acf_about.section1.image != null && (
                    <Img
                      fluid={
                        data.wpgraphql.page.acf_about.section1.image.imageFile
                          .childImageSharp.fluid
                      }
                      alt={data.wpgraphql.page.acf_about.section1.image.altText}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </article>
      </Layout>
    </Parallax>
  );
};

export default About;

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    wpgraphql {
      page(id: "cGFnZToxNA==") {
        title
        acf_page_meta {
          metadescription
        }
        acf_about {
          heroHead
          heroSubhead
          heroText
          topImageFront {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fixed(width: 460, height: 460, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          topImageBack {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fixed(width: 642, height: 428, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          section1 {
            head
            subhead
            text
            image {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 616, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        featuredImage {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
