import React from 'react';

const AnimatedE = (props) => (
  <svg className='logo-e' id="thebigE" style={{ opacity: 0 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.61 73.06">
    <title>Ideal Logo E</title>
    <g>
        <polygon id="frontBottom" points="0.60 55.10, 0.60 73.00, 48.60 73.00, 48.60 54.90, 0.60 55.10" fill="#8DC8E8"/>
        <polygon id="sideBottom" points="0.00 61.80, 0.60 55.10, 0.60 73.00, 0.00 70.50, 0.00 61.80" fill="#73AFCA"/>
      <g class="middlegroup">
        <polygon id="sideMiddle" points="0.00 44.70, 0.60 28.00, 0.60 45.20, 0.00 53.30, 0.00 44.70" fill="#00608B"/>
        <polygon id="frontMiddle" points="32.30 28.00, 0.60 27.90, 0.60 45.10, 32.30 45.10, 32.30 28.00" fill="#0076A8"/>
      </g>
      <g class="topgroup">
        <polygon id="frontTop" points="48.60 0.00, 0.60 0.00, 0.60 17.20, 48.60 17.20, 48.60 0.00" fill="#00426A"/>
        <polygon id="sideTop" points="0.00 26.50, 0.60 0.00, 0.60 17.20, 0.00 35.10, 0.00 26.500" fill="#00304D"/>
      </g>
    </g>
  </svg>
);

export default AnimatedE;
