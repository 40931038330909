import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl
} from "reactstrap";
import Img from "gatsby-image";

const LightboxCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(props.current);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = props.items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <Container>
          <Row>
            <Col xs={{ size: 14 }} sm={{ size: 7 }} md={{ size: 5, offset: 2 }} lg={{ size: 4 }}>
              <Img
                className="team-modal__photo"
                fluid={item.featuredImage.imageFile.childImageSharp.fluid}
              />
            </Col>
            <Col xs={{ size: 14 }} sm={{ size: 7 }} md={{ size: 6 }} lg={{ size: 5 }}>
              <h2 className="team-modal__name heading2">{item.title}</h2>
              <h4 className="team-modal__title">{item.acf_biography.title}</h4>
              <p className="body-copy">{item.acf_biography.biography}</p>
              <a className="cta-button cta-button--blue" href={`mailto:${item.acf_biography.email}`} target="_blank" rel="noopener noreferrer">
                {item.acf_biography.contactButton}
              </a>
            </Col>
          </Row>
        </Container>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={false}
    >
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default LightboxCarousel;
